
$(document).ready(function(){
    /** SWIPE FOR JCAROUSEL **/
    $('.jcarousel-control').jcarouselControl({
        carousel: $('.jcarousel')
    });

    /** EFFECT FOR TOGGLE **/
        if ($(window).width() < 751) {
            $(".navbar-toggle").click(function(){
                if ($(this).hasClass("collapsed") == true) {
                   $(".navbar-toggle .icon-bar").addClass("open");
                } else {
                   $(".navbar-toggle .icon-bar").removeClass("open");
                }
            });
        }

    /** MAGNIFIC POPUP INIT **/
    $('.popup-with-form').magnificPopup({
        type: 'inline',
        preloader: false,
        focus: '#name',
        callbacks: {
            beforeOpen: function() {
                if($(window).width() < 700) {
                    this.st.focus = false;
                } else {
                    this.st.focus = '#name';
                }
            }
        }
    });

    /** SMOOTH SCROLL **/
    $(function() {
        $('a[href*="#"]:not([href="#"]):not([href="#write-us"]):not([href="#detail-theme"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    return false;
                }
            }
        });
    });

    /** JQUERY VALIDATE FORM **/
        $('#write-us').validate({
            rules: {
                name: {
                    required: true
                },
                email: {
                    required: true,
                    email: true
                },
                phone: {
                    required: true,
                    number: true,
                    minlength: 9,
                    maxlength: 9
                },
                message: {
                    required: true
                }
            },
            highlight: function (element) {
                $(element).addClass('error').removeClass('valid');
                $(element).siblings('span').addClass('glyphicon-remove-sign').removeClass('glyphicon-ok-sign');
            },
            success: function (element) {
                $(element).removeClass('error').addClass('success');
                $(element).siblings('span').removeClass('glyphicon-remove-sign').addClass('glyphicon-ok-sign');
            }
        });
        jQuery.extend(jQuery.validator.messages, {
            required: "Toto pole je povinné.",
            email: "Prosím, zadejte validní e-mailovou adresu.",
            number: "Prosím, zadejte číslo v následujícím tvaru 111222333.",
            minlength: "Prosím, zadejte číslo v následujícím tvaru 111222333.",
            maxlength: "Prosím, zadejte číslo v následujícím tvaru 111222333."
        });

});


/** JCAROUSEL RESPONSIVE **/
(function ($) {
    $(function () {
        var jcarousel = $('.jcarousel');
        var prev = $('.jcarousel-control-prev');
        var next = $('.jcarousel-control-next');
        var wide4 = 830;
        var wide3 = 639;
        var wide2 = 360;

        jcarousel.on('jcarousel:reload jcarousel:create', function () {
            var carousel = $(this),
                width = carousel.innerWidth();

            if (width >= wide4) {
                width = width / 4;
                
                prev.jcarouselControl({
                    target: '-=4'
                });
        
                next.jcarouselControl({
                    target: '+=4'
                });
            } else if (width >= wide3) {
                width = width / 3;
                
                prev.jcarouselControl({
                    target: '-=3'
                });
        
                next.jcarouselControl({
                    target: '+=3'
                });
            } else if (width >= wide2) {
                width = width / 2;
                
                prev.jcarouselControl({
                    target: '-=2'
                });
        
                next.jcarouselControl({
                    target: '+=2'
                });
            } else {
                prev.jcarouselControl({
                    target: '-=1'
                });
        
                next.jcarouselControl({
                    target: '+=1'
                });
            }

            carousel.jcarousel('items').css('width', Math.ceil(width) + 'px');
        })
        .jcarousel({
            wrap: 'circular'
        });

        $('.jcarousel-pagination')
            .on('jcarouselpagination:active', 'a', function () {
            $(this).addClass('active');
        })
            .on('jcarouselpagination:inactive', 'a', function () {
            $(this).removeClass('active');
        })
            .on('click', function (e) {
            e.preventDefault();
        })
        .jcarouselPagination({
            item: function (page) {
                return '<a href="#' + page + '">' + page + '</a>';
            }
        });

        $(".jcarousel").swipe({
            swipeLeft: function (event, direction, distance, duration, fingerCount) {
                next.trigger('click');
            },
            swipeRight: function (event, direction, distance, duration, fingerCount) {
                prev.trigger('click');
            },
            //Default is 75px+ set to 0 for demo so any distance triggers swipe
            threshold: 30,
            excludedElements: "label, button, input, select, textarea, .noSwipe"
        });
    });
})(jQuery);